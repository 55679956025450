import { configureStore } from "@reduxjs/toolkit";
import storesReducer from "./features/storesSlice";
import dealsReducer from "./features/dealsSlice";
import authReducer from "./features/authSlice";
import pricesReducer from "./features/websiteSlices/pricesSlice";
import downloadsReducer from "./features/downloadsSlice";
import notificationReducer from "./features/notificationSlice";
import galleryReducer from "./features/gallerySlice";
import salesReducer from "./features/salesSlice";
import couponsReducer from "./features/couponsSlice";
import adminReducer from "./features/adminSlice";
import bannerofferReducer from "./features/websiteSlices/bannerofferSlice.js";
import jobPostReducer from "./features/websiteSlices/jobPostingSlice.js";
import storeSalesReducer from "./features/websiteSlices/storeSalesSlice.js";
import productsReducer from "./features/productsSlice";
import dealsAppReducer from "./features/dealsAppSlice.js";
import scrapReducer from "./features/scrapSlice.js";

const store = configureStore({
  reducer: {
    admin: adminReducer,
    stores: storesReducer,
    scrap: scrapReducer,
    deals: dealsReducer,
    userAuth: authReducer,
    prices: pricesReducer,
    downloads: downloadsReducer,
    notification: notificationReducer,
    gallery: galleryReducer,
    sales: salesReducer,
    coupons: couponsReducer,
    bannerOffer: bannerofferReducer,
    jobPost: jobPostReducer,
    storeSales: storeSalesReducer,
    products: productsReducer,
    dealsApp: dealsAppReducer,
  },
});

export default store;
