/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Paper,
    Stack,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography,
    tableCellClasses,
    TableBody,
    Toolbar,
    Button,
    TextareaAutosize,
    Autocomplete
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NavLink, useLocation } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useDispatch, useSelector } from "react-redux";
import { addDealsWeb, updateDealsWeb } from '../../../redux/features/dealsSlice';
import { getAllStoresData } from "../../../redux/features/storesSlice";
import dayjs from "dayjs";

// Date converter
function dateConverter(str) {
    // console.log(str, "str")
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`
}
// COMPONENT
function AddDeal() {
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { allStoresData } = useSelector((state) => ({ ...state.stores }))
    const [dealsData, setDealsData] = useState({
        id: state && state.dealData ? state.dealData.id : null,
        store_id: state && state.dealData ? state.dealData.store_id : "",
        store_name: state && state.dealData ? state.dealData.store_name : "",
        pid: state && state.dealData ? state.dealData.pid : "",
        deal_url: state && state.dealData ? state.dealData.deal_url : "",
        title: state && state.dealData ? state.dealData.title : "",
        description: state && state.dealData ? state.dealData.description : "",
        cashback_upto: state && state.dealData ? state.dealData.cashback_upto : "",
        img_url: state && state.dealData ? state.dealData.img_url : "",
        actual_price: state && state.dealData ? state.dealData.actual_price : "",
        disc_price: state && state.dealData ? state.dealData.disc_price : "",
        start_date: state && state.dealData ? dayjs(state.dealData.start_date) : dayjs(),
        end_date: state && state.dealData ? dayjs(state.dealData.end_date) : dayjs(),
    });

    // data handler
    const handleChange = (key) => (e) => {
        setDealsData({ ...dealsData, [key]: e.target.value });
    };

    // date picker
    const handleDatePicker = (name, dateString) => {
        console.log(name, dateString)
        setDealsData({ ...dealsData, [name]: dateConverter(dateString) })
    };
    // autocomplete handler
    const handleAutoComplete = (name) => {
        setDealsData({ ...dealsData, store_name: name });
        if (allStoresData.length > 0) {
            for (var i = 0; i < allStoresData.length; i++) {
                // //console.log(categoryData[i])
                if (name === allStoresData[i].store_name) {
                    setDealsData({
                        ...dealsData,
                        store_id: allStoresData[i].store_id,
                        store_name: allStoresData[i].store_name,
                    });
                }
            }
        }
    };
    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(dealsData)
        if (state && state.dealData)
            dispatch(updateDealsWeb(dealsData))
        else
            dispatch(addDealsWeb(dealsData))
        setDealsData({
            id: null,
            store_id: "",
            store_name: "",
            pid: "",
            deal_url: "",
            title: "",
            description: "",
            cashback_upto: "",
            img_url: "",
            actual_price: "",
            disc_price: "",
            start_date: dayjs(),
            end_date: dayjs(),
        })
    };
    useEffect(() => {
        dispatch(getAllStoresData());
    }, []);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3}>
                <form
                    onSubmit={handleSubmit}
                >
                    <Stack spacing={3} direction="column">
                        <Toolbar
                            mt={3}
                            sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                        >
                            <Typography
                                sx={{
                                    flex: "1 1 100%",
                                    color: "var(--primary-color)",
                                    fontWeight: "bold",
                                }}
                                variant="h5"
                                id="tableTitle"
                            >
                                Add New Deal
                            </Typography>
                            <NavLink to="/websitedata/show-deals">
                                <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap' }} startIcon={<ReplyIcon />}>
                                    <strong>Show Deals</strong>
                                </Button>
                            </NavLink>
                        </Toolbar>
                        <Table
                            size="small"
                            sx={{
                                width: "auto",
                                margin: "8px !important",
                                marginTop: "16px !important",
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <TableBody>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Store</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {/* <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Store ID"
                                        /> */}
                                        <Autocomplete
                                            freeSolo
                                            size="small"
                                            id="user-combo-box"
                                            name="store_name"
                                            value={dealsData.store_name}
                                            options={allStoresData && allStoresData.map((item) => item.store_name)}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Select Store" />
                                            )}
                                            onChange={(e, val) => handleAutoComplete(val)}

                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>PID</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter PID"
                                            value={dealsData.pid}
                                            onChange={handleChange('pid')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Deal URL</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Deal URL"
                                            value={dealsData.deal_url}
                                            onChange={handleChange('deal_url')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Title</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Title"
                                            value={dealsData.title}
                                            onChange={handleChange('title')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Description</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextareaAutosize
                                            minRows={1}
                                            maxRows={4}
                                            placeholder="Enter Description"
                                            style={{ width: "100%", padding: "8px 12px", fontSize: '1rem' }}
                                            value={dealsData.description}
                                            onChange={handleChange("description")}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Cashback Upto</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Cashback Amount"
                                            value={dealsData.cashback_upto}
                                            onChange={handleChange('cashback_upto')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Image URL</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Image URL"
                                            value={dealsData.img_url}
                                            onChange={handleChange('img_url')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Actual Price</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Actual Price"
                                            value={dealsData.actual_price}
                                            onChange={handleChange('actual_price')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Discounted Price</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Discounted Price"
                                            value={dealsData.disc_price}
                                            onChange={handleChange('disc_price')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Start Date</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <DateTimePicker
                                            placeholder="Start Date"
                                            value={dealsData.start_date}
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            onChange={(newVal) => handleDatePicker('start_date', newVal)}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>End Date</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell className='d-flex'>
                                        <DateTimePicker
                                            placeholder="End Date"
                                            value={dealsData.end_date}
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            onChange={(newVal) => handleDatePicker('end_date', newVal)}
                                        />
                                    </TableCell>
                                </TableRow>

                                
                                <TableRow>
                                    <TableCell colSpan={2} className="text-center">
                                        <Button startIcon={<SaveIcon />} variant="contained" size="large" sx={{ marginTop: 2, width: '200px' }}
                                            onClick={handleSubmit}>
                                            <b>Submit</b>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Stack>
                </form>
            </Paper>
        </LocalizationProvider>
    )
}

export default AddDeal