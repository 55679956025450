/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../Datatable/Datatable";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import {
  getStoreSchema,
  activateStoreSchema,
  getAllStoresData,
} from "../../redux/features/storesSlice";
import { Popconfirm } from "antd";
import {
  GetAllScrapdata,
  UpdateScrapData,
} from "../../redux/features/scrapSlice";

function ShowStoreSchema() {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState("");
  const { getStoreSchemaData, allStoresData } = useSelector((state) => ({
    ...state.stores,
  }));
  const { allScrapData } = useSelector((state) => ({ ...state.scrap }));
  console.log(allScrapData, "allScrapData");
  const handleStatus = (formData) => {
    console.log(formData, "fromdata");
    dispatch(UpdateScrapData(formData));
    // dispatch(activateStoreSchema(formData));
    dispatch(GetAllScrapdata());
  };

  const toggleExpansion = (e, details) => {
    setIsExpanded(details);
  };

  const handleReadLess = () => {
    setIsExpanded("");
  };

  const getStoreNameFromId = (id) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (allStoresData[i].store_id === id) {
        return allStoresData[i].store_name;
      }
    }
  };

  const columns = [
    {
      name: "edit",
      label: "Edit",
      options: {
        customBodyRender: (id, tableMeta) => {
          let data = {};
          // data.id = getStoreSchemaData.filter(
          //   (item) => item.store_id == tableMeta.rowData[2]
          // )[0].id;
          data.id = tableMeta.rowData[1];
          data.sid = tableMeta.rowData[3];
          data.url = tableMeta.rowData[4];
          data.remarks = tableMeta.rowData[5];

          return (
            <NavLink to="/scrap_urls/add-scrapeddata" state={{ data }}>
              <Button color="info" size="small" startIcon={<EditIcon />}>
                <strong>Edit</strong>
              </Button>
            </NavLink>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: " ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "product_count",
      label: "Product Count",
      options: {
        //   customBodyRender: (id) => {
        //     return getStoreNameFromId(id);
        //   },
        filter: true,
        sort: true,
      },
    },
    {
      name: "sid",
      label: "Store ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "url",
      label: "Url",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        // customBodyRender: (details) => {
        //   return (
        //     <>
        //       {isExpanded === details ? (
        //         <>
        //           <p
        //             style={{ padding: 0 }}
        //             dangerouslySetInnerHTML={{ __html: details }}
        //           ></p>
        //           <Button onClick={handleReadLess}>Read Less...</Button>
        //         </>
        //       ) : (
        //         <>
        //           <p
        //             style={{ padding: 0 }}
        //             dangerouslySetInnerHTML={{ __html: details.slice(0, 100) }}
        //           ></p>
        //           <Button onClick={(e) => toggleExpansion(e, details)}>
        //             Read More...
        //           </Button>
        //         </>
        //       )}
        //     </>
        //   );
        // },
        filter: true,
        sort: true,
      },
    },
    {
      name: "last_updated",
      label: "Last Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          var data = {};
          // data.store_id = tableMeta.rowData[1];
          // console.log(tableMeta.rowData, "tableMeta.rowData[1]");
          data.active = active ? "0" : "1";
          data.id = tableMeta.rowData[1];
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    // dispatch(getStoreSchema());
    dispatch(GetAllScrapdata());
  }, []);

  return (
    <div>
      <NavLink to="/scrap_urls/add-scrapeddata">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px", marginRight: "10px" }}
        >
          <strong>Add New Data</strong>
        </Button>
      </NavLink>
      <Datatable
        name="Trending Store Data"
        columns={columns}
        data={allScrapData ?? []}
      />
    </div>
  );
}

export default ShowStoreSchema;
