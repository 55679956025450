import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as scrapApi from "../api/scrapApi";
import { message } from "antd";
const initialState = {
  allScrapData: [],
  loading: false,
  error: "",
  success: "",
};

export const UpdateScrapData = createAsyncThunk(
  "/scrap/updatescrapdata",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await scrapApi.updateScrapData(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Action performed successfully"
        );
        // cbFunc();
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        // cbFunc();
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const InserScrapData = createAsyncThunk(
  "/scrap/inserscrapdata",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await scrapApi.insertScrapData(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Action performed successfully"
        );
        // cbFunc();
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        // cbFunc();
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const GetAllScrapdata = createAsyncThunk(
  "/scrap/getallscrapdata",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await scrapApi.getAllScrapdata();
      if (resp.data.code === 200) {
        console.log(resp.data.data, "resp.data");
        return resp.data.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const scrapSlice = createSlice({
  name: "scrap",
  initialState,
  //   reducers: {
  //     setStoreCategories: (state, action) => {
  //       state.storeCategories = action.payload;
  //     },
  //   },

  extraReducers: (builder) => {
    ///=================================getAllStoresData data===========================//////////////////////

    builder.addCase(UpdateScrapData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UpdateScrapData.fulfilled, (state, action) => {
      // state.loading = false;
      //   state.allStoresData = action.payload;
    });
    builder.addCase(UpdateScrapData.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(GetAllScrapdata.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllScrapdata.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload, "action.payload");
      state.allScrapData = action.payload;
    });
    builder.addCase(GetAllScrapdata.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(InserScrapData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(InserScrapData.fulfilled, (state, action) => {
      // state.loading = false;
      //   state.allStoresData = action.payload;
    });
    builder.addCase(InserScrapData.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
// export const { setStoreCategories } = scrapSlice.actions;
export default scrapSlice.reducer;
