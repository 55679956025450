/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch, useSelector } from "react-redux";

import {
  InserScrapData,
  UpdateScrapData,
} from "../../redux/features/scrapSlice";

function AddScrapUrls() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log(state.data, "state from link");
  const [updated, setUpdated] = useState(false);

  const [scrapData, setScrapData] = useState([
    {
      sid: state && state.data ? state.data.sid : "",
      url: state && state.data ? state.data.url : "",
      remarks: state && state.data ? state.data.remarks : "",
    },
  ]);
  useEffect(() => {
    if (state && state.data) setUpdated(true);
  }, []);

  // Editor state

  // const handleChange = (value) => {
  //   setScrapData({ ...scrapData, value });
  // };

  // Description type - text

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {};
    if (updated) {
      data.id = state && state.data && state.data.id;
      data.sid = +scrapData[0].sid;
      data.url = scrapData[0].url;
      data.remarks = scrapData[0].remarks;
      data.product_count = scrapData[0].product_count;
      // console.log(data, "data to send");
      dispatch(UpdateScrapData(data));
    } else {
      data.sid = +scrapData[0].sid;
      data.url = scrapData[0].url;
      data.remarks = scrapData[0].remarks;
      data.product_count = scrapData[0].product_count;
      dispatch(InserScrapData(data));
    }
    navigate("/scrap_urls/add-showscrapeddata");

    // dispatch(addStoreSchema(data));
  };

  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              display: "flex",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
                flex: "1 1 100%",
              }}
              variant="h5"
            >
              Add Data
            </Typography>
            <NavLink to="/scrap_urls/add-showscrapeddata">
              <Button
                variant="contained"
                color="info"
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<ReplyIcon />}
              >
                <strong>Go Back</strong>
              </Button>
            </NavLink>
          </Toolbar>

          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
              "&.css-dsuxgy-MuiTableCell-root": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              },
            }}
          >
            <div className="main">
              {scrapData?.map((data, index) => (
                <div key={index}>
                  {Object.keys(data).map((key) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ marginRight: "8px", flex: 1 }}
                      >
                        <b>{key}</b>
                      </Typography>
                      <TextField
                        style={{ flex: 1 }}
                        label={key}
                        value={data[key]}
                        onChange={(e) => {
                          const updatedData = [...scrapData];
                          updatedData[index][key] = e.target.value;
                          setScrapData(updatedData); // Update the state
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <TableBody>
              {/* Submit button row */}
              <TableRow>
                <TableCell colSpan={2} className="text-center">
                  <Button
                    variant="contained"
                    type="submit"
                    color="info"
                    startIcon={<SaveIcon />}
                    sx={{ marginLeft: "15px" }}
                  >
                    <strong>Submit</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
}

export default AddScrapUrls;
